import * as XLSX from 'xlsx';
import {JSON2SheetOpts} from 'xlsx';

export function watchUntilExists(instance: any, path: string) {
  return new Promise(resolve => {
    const paths = path.split('.');
    const id = setInterval(() => {
      paths.reduce((instance, key, index) => {
        if (!instance) return null;
        if (index === paths.length - 1) {
          clearInterval(id);
          resolve(instance);
        }
        return instance[key];
      }, instance);
    })
  });
}

let ctx2d: CanvasRenderingContext2D;

export function measureText(text: string) {
  if (!ctx2d) {
    ctx2d = document.createElement('canvas').getContext('2d')!;
  }
  return ctx2d.measureText(text).width * 1.4;
}

export interface XlsxSheet {
  name?: string;
  values?: { [key: string]: string | number | Date }[];
  options?: JSON2SheetOpts;
}

export function exportSheet(sheet: XlsxSheet, name: string, type = 'application/octet-stream') {
  return exportSheets([sheet], name, type);
}

export function exportSheets(sheets: XlsxSheet[], name: string, type = 'application/octet-stream') {
  const wb = {
    SheetNames: [] as string[],
    Sheets: {},
    Props: {},
  };

  sheets.forEach((item, index) => {
    const name = item.name || `Sheet${index}`;
    wb.SheetNames.push(name);
    wb.Sheets[name] = XLSX.utils.json_to_sheet(item.values || [], item.options);

    // 自动计算列宽
    if (item.values && item.values[0]) {
      const keys = Object.keys(item.values[0]);
      const cols = keys.map(key => {
        return {wpx: measureText(key)};
      });
      item.values.forEach(item => {
        keys.forEach((key, index) => {
          if (!item[key]) return;
          let wpx: number;
          if (item[key] instanceof Date) {
            wpx = measureText('0000-00-00 00:00:00');
          } else {
            wpx = measureText(item[key].toString());
          }
          if (wpx > cols[index].wpx) {
            cols[index].wpx = wpx;
          }
        });
      });
      wb.Sheets[name]['!cols'] = cols;
    }
  });

  const blob = new Blob([
    s2ab(
      XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
        cellStyles: true,
        cellDates: true,
      }),
    ),
  ], {type});

  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.xlsx`;
  link.click();

  setTimeout(() => {
    URL.revokeObjectURL(link.href);
  }, 100);
}

export function s2ab(s) {
  if (typeof ArrayBuffer !== 'undefined') {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  } else {
    const buf = new Array(s.length)
    for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
    return buf as any;
  }
}
