
import {Component, Vue} from 'vue-property-decorator';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import XTable from '@/components/x-table/x-table.vue';
import {$xTable, XTableProxy, XTableStdRequestParams, XTableStdResponse} from '@/components/x-table/table.proxy';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import {$goodsCategory} from '@/pages/goods/services/goods-category';
import * as $GoodsApi from '@/api/goods';
import XSortable from '@/components/x-sortable/x-sortable.vue';

@Component({
  name: 'supplier-goods-selector',
  components: {
    XSortable,
    XDialog,
    XTable
  },
})
export default class SupplierGoodsSelector extends Vue {
  multiple = true;
  category = $goodsCategory.create();
  localCategory = '';
  JDCategory = $goodsCategory.JD;
  resolveResults: any = null!;
  tableProxy: XTableProxy = null!;
  extraSorts: any = {sort_type: 'winsdate_desc'};
  noCategoriesGoods = [];
  errorGoodsMap = {} as any;

  dialogProxy = $xDialog.create({
    title: '京东商品',
    wrapperClass: 'supplier-goods-selector',
    disableConfirm: true,
    disableCancel: true,
    width: '90vw',
  });

  categorySelectorDialog = $xDialog.create({
    title: '调整商品分类',
    wrapperClass: 'category-selector',
    beforeConfirm: () => this.confirm(),
    width: '364px',
    showClose: false,
  });

  select(multiple = true) {
    this.JDCategory.tryReload();
    this.multiple = multiple;
    this.dialogProxy.display();
    this.JDCategory.tryReload();
    return new Promise<any[]>(resolve => {
      this.resolveResults = resolve;
      this.buildTableProxy();
    });
  }

  beforeConfirm() {
    const data = this.tableProxy.exportData();
    if (!data.length) return this.$message.error('请挑选至少一件商品');
    this.confirm('', data);
  }

  confirm(localCategory = this.localCategory || '||', data: any[] = this.noCategoriesGoods || []) {
    console.log(data)
    return new Promise<boolean>(resolve => {
      const categories = localCategory.split('|').filter(item => !!item);

      let loading = this.$loading({text: '正在上传商品...'});

      $GoodsApi.getJDchoseJdGoods({
        category_id: categories.pop(),
        jd_goods_ids: data.map(item => item.ware_id),
      }).then(resp => {
        loading.close();

        loading = this.$loading({text: '商品上传成功，正在加载结果...'});

        const messages = [`本次共成功挑选${data.length - resp.message.length}件商品，另有：`];
        const errors: string[] = [];

        if (resp.result !== 1) {
          const notFoundGoods = resp.message.filter(msg => msg.err.includes('未找到')).map(msg => {
            this.errorGoodsMap[msg.id] = true;
            return msg;
          });
          const appendedGoods = resp.message.filter(msg => msg.err.includes('已经添加')).map(msg => {
            this.errorGoodsMap[msg.id] = true;
            return msg;
          });
          const noCategoriesGoods = resp.message.filter(msg => msg.err.includes('本地库分类')).map(msg => {
            return {
              ware_id: msg.id,
            }
          });

          if (notFoundGoods.length > 0) {
            errors.push(`${notFoundGoods.length}件商品已从京东下架`);
          }

          if (appendedGoods.length > 0) {
            errors.push(`${appendedGoods.length}件商品已添加到本地`);
          }

          if (noCategoriesGoods.length > 0) {
            this.noCategoriesGoods = noCategoriesGoods;
            this.categorySelectorDialog.display();
            errors.push(`${noCategoriesGoods.length}件商品需要调整分类`);
          }
        }

        this.tableProxy.select.reset();
        this.tableProxy.request().then(() => {
          loading.close();

          if (resp.result === 1) {
            this.$message.success({
              message: '商品挑选成功',
              duration: 4000
            });
            // this.dialogProxy.dismiss();
            resolve(true);
            this.resolveResults();
          } else {
            messages.push(errors.join('，') + '。');

            this.$message.error({
              message: messages.join(''),
              duration: 5000,
            });
          }
        });
      });
    });
  }

  onSortChange(key, sort) {
    if (sort) {
      this.extraSorts = {
        sort_type: `${key}_${sort}`
      };
    } else {
      this.extraSorts = {};
    }

    this.tableProxy.clearSort!();
    this.tableProxy.request();
  }

  buildTableProxy() {
    if (this.tableProxy) {
      return this.tableProxy.reset();
    }

    this.tableProxy = $xTable.create({
      key: 'ware_id',
      basic: {
        pageSize: 10,
      },
      select: {
        checkRowSelectable: (row) => {
          return row.sale_state === 1 && !row.is_have;
        }
      },
      search: {
        formData() {
          return {
            goods_source: 1,
            sort_type: '',
            // 搜索关键字（中文编码）
            keyword: '',
            // 京东馆价钱最小值
            min: '',
            // 京东馆价钱最大值
            max: '',
            // 一级分类
            cid1: '',
            // 二级分类
            cid2: '',
            // 三级分类
            cat_id: ''
          };
        },
        rules(ctx) {
          return {
            min: [{
              trigger: ['blur'],
              validator: (rule, value, callback) => {
                if (ctx.formData.min === undefined || ctx.formData.max === undefined) {
                  return callback();
                }

                if (ctx.formData.min > ctx.formData.max) {
                  return callback(new Error('最低成本价不可大于最高成本价'));
                }

                callback();
              }
            }],
          }
        },
        onSortChange: (parent, prop, order) => {
          this.extraSorts = {};
          return {
            sort_type: order ? prop + '_' + (order.includes('asc') ? 'asc' : 'desc') : '',
          };
        }
      },
      onRequest: (params: XTableStdRequestParams): Promise<XTableStdResponse> => {
        const extraParams = {} as any;

        if (params.search) {
          if (params.search.categories) {
            [extraParams.cid1, extraParams.cid2, extraParams.cat_id] = params.search.categories;
          }
          const {
            min,
            max
          } = params.search;
          extraParams.min = min || '';
          extraParams.max = max || '';
        }

        return $GoodsApi.getJDGoodsList({
          page_index: params.pageIndex,
          page_size: params.pageSize,
          keyword: params.search.keyword,
          ...extraParams,
          ...params.sort,
          ...this.extraSorts,
        }).then(resp => {
          if (!resp) return null!;
          return {
            data: resp.hit_result.map(item => {
              item.$selected = !!item.is_have;
              item.$unselectable = item.$selected || this.errorGoodsMap[item.ware_id];
              item.profit = ((item.jd_price - item.price) / item.jd_price);
              if (isNaN(item.profit)) item.profit = 0;
              return item;
            }),
            data_total: resp.result_count,
            page_no: resp.page_index,
            page_size: resp.page_size,
          }
        });
      }
    });
  }
}
